<template>
  <div class="BackStageUser">
    <div class="search">
      <el-row>
        <el-col :span="5">
          <div class="search_input">
            <el-input
              v-model="condition"
              placeholder="请输入查找关键字"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="search_input">
            <el-button class="btn" type="primary" @click="reset"
              >重置</el-button
            >
            <el-button class="btn" type="primary" @click="getList"
              >搜索</el-button
            >
          </div>
        </el-col>
        <el-col :offset="12" :span="1">
          <div class="search_input">
            <el-button
              class="btn"
              icon="el-icon-plus"
              type="primary"
              @click="handleCodeAdd('ruleForm')"
              >新增</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <template>
          <el-table-column type="index" align="center" width="150" label="序号">
          </el-table-column>
          <el-table-column prop="userName" align="center" label="用户名">
          </el-table-column>
          <el-table-column prop="phone" align="center" label="手机号">
          </el-table-column>
          <el-table-column prop="realName" align="center" label="真实姓名">
          </el-table-column>
          <el-table-column prop="createTime" align="center" label="创建时间">
          </el-table-column>
        </template>
        <el-table-column fixed="right" width="250" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleLook(scope.$index, scope.row)"
              >查看</el-button
            >
            <el-button
              size="mini"
              type="danger"
              plain
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="page.pageSize"
          layout="total, prev, pager, next"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 新增 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      custom-class="dialog"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        :disabled="disabled"
      >
        <el-form-item label="用户名称" prop="userName">
          <el-input v-model="form.userName"></el-input>
        </el-form-item>
        <el-form-item label="用户密码" prop="passWord">
          <el-input v-model="form.passWord" show-password></el-input>
        </el-form-item>
        <el-form-item label="用户手机" prop="phone">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" prop="realName">
          <el-input v-model="form.realName"></el-input>
        </el-form-item>
        <el-form-item label="用户权限" prop="realName" style="text-align: left">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <!-- <div style="margin: 15px 0"></div> -->
          <el-checkbox-group
            v-model="form.roleBean"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox v-for="city in cities" :label="city" :key="city">{{
              city
            }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MoTable from "../../../components/MoTable/MoTable";
export default {
  name: "BackStageUser",
  components: {
    MoTable,
  },
  data() {
    return {
      // 新增
      dialogVisible: false,
      locationName: "",
      condition: "",
      title: "",
      disabled: false,
      form: {
        userName: null,
        passWord: null,
        phone: null,
        userRoleId: 1,
        realName: null,
        roleBean: [],
      },
      tableData: [
        {
          userName: "测试",
          phone: "15",
          createTime: "2017-02-01",
          realName: "李尚",
          passWord: "156165",
        },
        {
          userName: "测试222",
          phone: "12",
          createTime: "2017-02-01",
          realName: "库尚",
          passWord: "156165",
        },
      ],
      url: "",
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 11,
      },
      checkAll: false,
      cities: [
        "驿站信息",
        "社区服务",
        "预约管理",
        "用户管理",
        "线索管理",
        "任务管理",
        "公益管理",
        "商品管理",
        "骑手风采",
        "系统管理",
      ],
      isIndeterminate: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      this.condition = "";
      this.getList();
    },
    handleCheckAllChange(val) {
      this.form.roleBean = val ? this.cities : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    // 获取
    async getList() {
      const res = await this.$get("/user/list", {
        ...this.page,
        condition: this.condition,
      });
      if (res.flag) {
        this.tableData = res.data.rows;
        this.page.total = res.data.total;
      }
    },
    // 删除
    handleDelete(index, row) {
      this.$confirm("确认删除？")
        .then((_) => {
          if (sessionStorage.getItem("userName") == row.userName) {
            this.$message.error("不可删除自己哦");
          } else {
            this.codeDelete(row);
          }
        })
        .catch((_) => {});
    },
    async codeDelete(row) {
      const res = await this.$del("/user/del/" + row.id);
      if (res.flag) {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },

    handleLook(index, row) {
      this.form = row;
      this.title = "查看用户";
      if (row.roleBean.length == 7) {
        this.checkAll = true;
      } else {
        this.isIndeterminate = true;
      }
      this.dialogVisible = true;
      // this.handleCheckAllChange(row.roleBean)
    },
    handleCurrentChange(n) {
      this.page.pageNum = n;
      this.getList();
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    // 新增
    handleCodeAdd(formName) {
      this.form = {
        userName: null,
        passWord: null,
        phone: null,
        userRoleId: 1,
        realName: null,
        roleBean: [],
      };
      this.checkAll = false;
      this.isIndeterminate = false;
      this.title = "添加用户";
      this.dialogVisible = true;
    },
    submitForm(formName) {
      console.log(111);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.form.userRoleId = 1;
          const res = await this.$post("/oauth/backedRegister", this.form);
          console.log(res);
          if (res.flag) {
            this.dialogVisible = false;
            this.$message.success(res.message);
            this.getList();
          }
        } else {
          this.$message.error(res.message);
          return false;
        }
      });
    },
  },
  computed: {
    rules() {
      return {
        userName: [{ required: true, message: "请输入名称", trigger: "blur" }],
        passWord: [{ required: true, message: "请输入密码", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        realName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
      };
    },
  },
  watch: {
    title: {
      handler(val) {
        if (val == "查看用户") {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  width: 100%;
  height: 80px;
  background: #ffffff;
  border-radius: 16px;
  margin-top: 16px;
  .search_input {
    height: 80px;
    display: flex;
    align-items: center;

    padding: 0 30px;
    .btn {
      width: 110px;
      height: 40px;
    }
  }
}
.name {
  line-height: 40px;
}
.table {
  margin-top: 20px;
  // height: 100%;
  background-color: #fff;
  // /deep/ .has-gutter {
  //   height: 78px;
  //   tr {
  //     th {
  //       background-color: #e9eef3;
  //     }
  //   }
  // }
  .pagination {
    padding: 30px 0;
    display: flex;
    justify-content: flex-end;
    /deep/ .el-pagination {
      .el-pagination__sizes {
        margin-right: 100px;
      }
      .el-pagination__jump {
        margin-right: 100px;
      }
    }
  }
}
</style>
